<i18n lang="yaml">
ja:
  term: 開催日
en:
  term: Event Date
</i18n>

<template>
  <div class="ho-play-event-card-date">
    <div class="inner">
      <IconEvent class="icon" />
      <!-- 横幅が大きいときに表示 -->
      <span class="date -full">{{ eventDateFull }}</span>
      <!-- 横幅が小さいときに表示 -->
      <span class="date -short">{{ eventDateShort }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconEvent from '@/assets/icons/icon_jst_event.svg'

// modules
import {
  formatJapaneseEventTime,
  formatEnglishEventTime,
} from '@/utils/date-control'

const i18n = useI18n()

type Props = {
  startDate: string | Date
  endDate?: string | Date
}

const props = defineProps<Props>()

const isJa = computed(() => i18n.locale.value === 'ja')
const startAtJST = computed(() => formatLocalTimezoneToJST(props.startDate))
const endAtJST = computed(() => {
  if (!props.endDate) return ''
  return formatLocalTimezoneToJST(props.endDate)
})

const eventDateFull = computed(() => {
  return `${
    isJa.value
      ? formatJapaneseEventTime(startAtJST.value, endAtJST.value)
      : formatEnglishEventTime(startAtJST.value, endAtJST.value)
  }`
})

const eventDateShort = computed(() => {
  return `${
    isJa.value
      ? formatJapaneseEventTime(startAtJST.value)
      : formatEnglishEventTime(startAtJST.value)
  }`
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-play-event-card-date {
  background: v.$gray;
  border-radius: 50px;
  width: 100%;

  > .inner {
    align-items: center;
    color: v.$text-body;
    container-type: inline-size;
    display: flex;
    padding: v.space(1) v.space(3);

    > .date {
      font-size: 14px;
      font-weight: 700;

      &.-full {
        display: block;
      }

      &.-short {
        display: none;
      }

      @container (max-width: 270px) {
        &.-full {
          display: none;
        }

        &.-short {
          display: block;
        }
      }

      @include m.sp {
        font-size: 12px;
      }
    }

    > .icon {
      display: block;
      fill: v.$text-body;
      height: 16px;
      margin-right: v.space(1);
      width: 16px;

      @include m.sp {
        height: 12px;
        width: 12px;
      }
    }

    @include m.sp {
      font-size: 14px;
      padding: v.space(1);
    }
  }
}
</style>
